<template>
    <a-layout>
        <a-layout-header style="background: #fff; padding: 0"/>
        <a-layout-content style="margin: 0 16px">
            <a-breadcrumb style="margin: 16px 0">
                <a-breadcrumb-item>首页</a-breadcrumb-item>
                <a-breadcrumb-item>已发送邮件列表</a-breadcrumb-item>
            </a-breadcrumb>
            <a-alert message="页面翻页还有bug 待查" type="info" show-icon style="margin-bottom: 10px" closable/>

            <div class="toolsbar">
                <div style="float: left;margin-left: 20px;margin-top: 10px;">
                    <!--                    <a-button type="primary" @click="addgift()">新建邮件</a-button>-->

                    <a-button type="primary" :loading="loading" @click="start" style="margin-left: 0px">
                        刷新
                    </a-button>
                </div>
                <div class="serach_input">
                    <a-radio-group default-value="all" button-style="solid" @change="onChangetop">
                        <a-radio-button value="all">
                            全部
                        </a-radio-button>
                        <a-radio-button value="addGifs,updateGifs">
                            礼包
                        </a-radio-button>
                        <a-radio-button value="mails">
                            Email
                        </a-radio-button>
                        <a-radio-button value="addCodes,updateCodes,startCodes">
                            激活码
                        </a-radio-button>
                        <a-radio-button value="addC2is,updateC2is">
                            活动
                        </a-radio-button>
                        <a-radio-button value="addGglpools,updateGglpools,deleteGglpools,addGgls,updateGgls,deleteGgls">
                            刮刮乐
                        </a-radio-button>
                        <a-radio-button value="addChargegifs,updateChargegifs">
                            充值礼包
                        </a-radio-button>
                        <a-radio-button value="updateActives">
                            活动列表
                        </a-radio-button>
                        <a-radio-button value="addCrontabs,startCrontabs">
                            定时任务
                        </a-radio-button>
                        <a-radio-button value="addBlocks,freeBlocks,banBnbs,pickBans">
                            禁言/踢人/封号
                        </a-radio-button>
                    </a-radio-group>
                    <!--                    <a-input-group compact>-->
                    <!--                        <a-select defaultValue="用户名">-->
                    <!--                            <a-select-option value="username">用户名</a-select-option>-->
                    <!--                        </a-select>-->
                    <!--                        <a-input-search-->
                    <!--                            style="width: 64%"-->
                    <!--                            defaultValue-->
                    <!--                            @search="onSearch"-->
                    <!--                            enterButton-->
                    <!--                        />-->
                    <!--                    </a-input-group>-->
                </div>
            </div>

            <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
                <!--                {{data}}-->
                <!--                {{inforesdata}}-->
                 <a-table
                        bordered
                        :columns="columns"
                        :dataSource="data"
                        :pagination="pagination"
                        :loading="loading"
                        rowKey="id"
                        @change="onChange"
                >
                    <template slot="operation" slot-scope="text, index">
                        <div class="editable-row-operations">
                            <a @click="viewinfo(index)">查看详细信息</a>
                        </div>
                    </template>
                    <template slot="type" slot-scope="text, index">
                        <a-tag :color="'green'" v-if=" index.type === 'addGifs'"><span> 添加礼包 </span></a-tag>
                        <a-tag :color="'green'" v-if=" index.type === 'updateGifs'"><span> 更新礼包 </span></a-tag>

                        <a-tag :color="'pink'" v-if=" index.type === 'mails'"><span> 发送eMail </span></a-tag>

                        <a-tag :color="'orange'" v-if=" index.type === 'addCodes'"><span> 新增激活码 </span></a-tag>
                        <a-tag :color="'orange'" v-if=" index.type === 'updateCodes'"><span> 更新激活码 </span></a-tag>
                        <a-tag :color="'orange'" v-if=" index.type === 'startCodes'"><span> 激活/删除 激活码 </span></a-tag>

                        <a-tag :color="'red'" v-if=" index.type === 'addC2is'"><span> 添加充值金额兑换道具 </span></a-tag>
                        <a-tag :color="'red'" v-if=" index.type === 'updateC2is'"><span> 更新充值金额兑换道具 </span></a-tag>

                        <a-tag :color="'red'" v-if=" index.type === 'addR2rs'"><span> 添加资源兑换 </span></a-tag>
                        <a-tag :color="'red'" v-if=" index.type === 'updateR2rs'"><span> 修改资源兑换 </span></a-tag>

                        <a-tag :color="'cyan'" v-if=" index.type === 'addGglpools'"><span> 添加刮刮乐池子 </span></a-tag>
                        <a-tag :color="'cyan'" v-if=" index.type === 'updateGglpools'"><span> 更新刮刮乐池子 </span></a-tag>
                        <a-tag :color="'cyan'" v-if=" index.type === 'deleteGglpools'"><span> 删除刮刮乐池子 </span></a-tag>

                        <a-tag :color="'cyan'" v-if=" index.type === 'addGgls'"><span> 添加刮刮乐活动 </span></a-tag>
                        <a-tag :color="'cyan'" v-if=" index.type === 'updateGgls'"><span> 修改刮刮乐活动 </span></a-tag>
                        <a-tag :color="'cyan'" v-if=" index.type === 'deleteGgls'"><span> 删除刮刮乐活动 </span></a-tag>

                        <a-tag :color="'blue'" v-if=" index.type === 'addChargegifs'"><span> 添加充值礼包 </span></a-tag>
                        <a-tag :color="'blue'" v-if=" index.type === 'updateChargegifs'"><span> 修改充值礼包 </span></a-tag>

                        <a-tag :color="'purple'" v-if=" index.type === 'updateActives'"><span> 修改活动列表 </span></a-tag>
                        <a-tag :color="'purple'" v-if=" index.type === 'addT2rs'"><span> 添加玩法类型 </span></a-tag>
                        <a-tag :color="'purple'" v-if=" index.type === 'updateT2rss'"><span> 修改玩法类型 </span></a-tag>

                        <a-tag color="#108ee9" v-if=" index.type === 'addCrontabs'"><span> 添加定时任务 </span></a-tag>
                        <a-tag color="#108ee9" v-if=" index.type === 'sendNote'"><span> 修改公告 </span></a-tag>
                        <a-tag color="#108ee9" v-if=" index.type === 'startCrontabs'"><span>启动/删除定时任务 </span></a-tag>

                        <a-tag color="#101ee9" v-if=" index.type === 'addBlocks'"><span>添加禁言</span></a-tag>
                        <a-tag color="#101ee9" v-if=" index.type === 'freeBlocks'"><span>解除禁言</span></a-tag>
                        <a-tag color="#101ee9" v-if=" index.type === 'banBnbs'"><span>添加封号</span></a-tag>
                        <a-tag color="#101ee9" v-if=" index.type === 'pickBans'"><span>解除封号</span></a-tag>
                    </template>
                    <template slot="return_data" slot-scope="text, index">
<!--                        {{text}}-->
                        <a-tag :color="'green'" v-if="JSON.parse(index.return_data).opt_code === 0"><span>正常</span></a-tag>

                        <a-tag :color="'red'" v-if="JSON.parse(index.return_data).opt_code !== 0">异常</a-tag>
<!--                        <a-tag :color="'red'" v-if="index.return_data == 'sb'">发送成功</a-tag>-->
                        <!--                        <span>{{JSON.parse(index.return_data).opt_code}}</span>-->
                    </template>
                </a-table>
            </div>
        </a-layout-content>
        <a-layout-footer style="text-align: center"></a-layout-footer>

        <a-drawer
                title="礼包列表"
                :width="720"
                :visible="visible"
                :body-style="{ paddingBottom: '80px' }"
                @close="onClose"
        >
            <a-descriptions :title=view.id bordered
                            :column=1
            >
                <a-descriptions-item label="创建时间">
                    <div style="max-width: 460px;">{{view.crate_time}}</div>
                </a-descriptions-item>
                <a-descriptions-item label="提交数据包">
                    <div style="max-width: 460px;">{{view.up_data}}</div>
                </a-descriptions-item>
                <a-descriptions-item label="返回数据包">
                    <div style="max-width: 460px;">{{view.return_data}}</div>
                </a-descriptions-item>
                <a-descriptions-item label="提交人uid">
                    <div style="max-width: 460px;">[{{view.uid}}]<span v-html="view.name"></span></div>
                </a-descriptions-item>
                <a-descriptions-item label="登录ip">
                    <div style="max-width: 460px;">{{view.user_ip}}</div>
                </a-descriptions-item>
            </a-descriptions>
            <div
                    :style="{
                      position: 'absolute',
                      right: 0,
                      bottom: 0,
                      width: '100%',
                      borderTop: '1px solid #e9e9e9',
                      padding: '10px 16px',
                      background: '#fff',
                      textAlign: 'right',
                      zIndex: 1,
                    }"
            >

                <a-button :style="{ marginRight: '8px' }" @click="onClose">
                    Cancel
                </a-button>
                <a-button type="primary" @click="onClose">
                    Submit
                </a-button>
            </div>
        </a-drawer>

    </a-layout>
</template>

<script>

    // 假数据
    // const serverdatas = [
    //     {
    //         id: '1',
    //         name: 'John Brown',
    //         start: 32,
    //     },
    //     {
    //         id: '2',
    //         name: 'Joe Black',
    //         start: 42,
    //     },
    //     {
    //         id: '3',
    //         name: 'Jim Green',
    //         start: 32,
    //     },
    //     {
    //         id: '4',
    //         name: 'Jim Red',
    //         start: 32,
    //     },
    // ];


    // 假数据结束
    const columns = [
        {
            title: "id",
            dataIndex: "id",
            width: "15%",
            scopedSlots: {customRender: "id"}
        },
        {
            title: "名字",
            dataIndex: "name",
            scopedSlots: {customRender: "name"}
        },
        {
            title: "操作时间",
            dataIndex: "crate_time",
            scopedSlots: {customRender: "crate_time"}
        },
        {
            title: "登录ip",
            dataIndex: "user_ip",
            scopedSlots: {customRender: "user_ip"}
        },
        {
            title: "操作类型",
            dataIndex: "type",
            scopedSlots: {customRender: "type"}
        }, {
            title: "状态",
            dataIndex: "return_data",
            scopedSlots: {customRender: "return_data"}
        },
        {
            title: '操作',
            width: "25%",
            key: 'operation',
            scopedSlots: {customRender: 'operation'}
        },
    ];


    import {Modal} from "ant-design-vue";
    import Storage from "../../common/storage";
    import moment from "moment";
    // import * as Util from "../common/util";

    export default {
        name: "Mail",
        components: {},


        data() {
            return {
                columns,
                data: [],
                Searchdata: Storage.resourcedatalist,

                pagination: {
                    pageSize: 15
                },
                search_text: "all",


                loading: false,

                visible: false,

                add: {
                    title: "",
                    content: "",
                    gif_id: "",
                    gif_name: "",
                    targets: [],
                },
                view: {
                    crate_time: "",
                    id: "",
                    name: "",
                    return_data: "",
                    type: "",
                    uid: "",
                    up_data: "",
                    user_ip: "",
                },

            };
        },
        created() {
            this.loading = true;
            // Promise.all([this.getitem(), this.getresource()]).then(() => {
            //     this.getgifts("", 1, this.pagination.pageSize);
            // }).catch((error) => {
            //     console.log(error)
            // })

            // this.getitem();
            // this.getserverlist(1,20);
            // this.getresource();


            // 获取第一页内容
            this.doSearch(this.search_text, 1, this.pagination.pageSize);
        },
        methods: {
            start() {
                this.loading = true;
                this.data = [];
                // ajax request after empty completing
                setTimeout(() => {
                    this.loading = false;
                    this.selectedRowKeys = [];
                }, 1000);
                this.doSearch(this.search_text, 1, this.pagination.pageSize);
            },
            async onSearch(text) {
                this.doSearch(text, 1, this.pagination.pageSize);
            },

            async onChange(pagination) {
                this.loading = true;
                this.data = [];
                await this.doSearch(
                    this.search_text,
                    pagination.current,
                    pagination.pageSize
                );
            },
            async onChangetop(e) {
                console.log(`checked = ${e.target.value}`);
                this.doSearch(e.target.value, 1, this.pagination.pageSize);
            },


            async doSearch(account, start, num) {
                console.log(`checked`,account);
                this.loading = true;
                this.data = [];
                let now = Date.now();
                let result = await this.$get("/api/admin/log/list", {
                    params: {
                        uid: Storage.uid,
                        token: Storage.token,
                        page: start,
                        limit: num,
                        type: account,
                        t: now,
                    }
                });

                if (result.status == true) {

                    let data = [];
                    if (result.data == "404 page not found") {
                        Modal.error({
                            title: "api服务器接口错误"
                        });
                        return
                    }

                    for (let i in result.data.list) {

                        let info = result.data.list[i];
                        let offset = (parseInt(start) - 1) * num + parseInt(i);
                        data[offset] = {
                            id: info.id,
                            uid: info.uid,
                            name: info.name,
                            user_ip: info.user_ip,
                            type: info.type,
                            up_data: info.up_data,
                            return_data: info.return_data,
                            // start: info.start,
                            crate_time: moment(info.crate_time * 1000).format("YYYY/MM/DD hh:mm:ss"),
                        };
                    }

                    this.data = data;
                    console.log(this.data)
                    let pagination = {...this.pagination};
                    pagination.total = result.data.total;
                    this.pagination = pagination;

                    this.loading = false;
                }

                this.loading = false;
            },


            async onChanges(value) {
                this.Search_target = value
                console.log("selected", this.Search_target);
                if (this.Search_target == "resource_table"){
                    this.Searchdata = Storage.resourcedatalist
                }
                if (this.Search_target == "item_table"){
                    this.Searchdata = Storage.itemdatalist
                }
            },
            viewinfo(index) {
                this.view.crate_time = index.crate_time,
                    this.view.id = index.id,
                    this.view.name = index.name,
                    this.view.return_data = index.return_data,
                    this.view.type = index.type,
                    this.view.uid = index.uid,
                    this.view.up_data = index.up_data,
                    this.view.user_ip = index.user_ip,
                    this.visible = true;
            },
            showserver() {
                this.servervisible = true;
            },
            onClose() {
                this.visible = false;
                this.servervisible = false;
            },
        }
    };
</script>
<style scoped>
    .editable-row-operations a {
        margin-right: 8px;
    }

    #components-layout-demo-custom-trigger .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;
    }

    #components-layout-demo-custom-trigger .trigger:hover {
        color: #1890ff;
    }

    #components-layout-demo-custom-trigger .logo {
        height: 32px;
        background: rgba(255, 255, 255, 0.2);
        margin: 16px;
    }

    .imgs {
        text-align: center;
        display: block;
    }

    .imgs img {
        height: 200px;
        width: auto;
    }

    .serach_input {
        width: 75vw;
        float: right;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: right;
        margin-right: 25px;
        z-index: 1000;
    }

    .pages {
        float: right;
        margin-top: 10px;
    }

    .toolsbar {
        display: block;
        width: 100%;
        height: 42px;
        background: #fff;
    }
</style>
